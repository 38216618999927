/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 foodies app
  Created : 28-Feb-2021
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
export const Spinkit = {
  skChasingDots: 'sk-chasing-dots',
  skCubeGrid: 'sk-cube-grid',
  skDoubleBounce: 'sk-double-bounce',
  skRotatingPlane: 'sk-rotationg-plane',
  skSpinnerPulse: 'sk-spinner-pulse',
  skThreeBounce: 'sk-three-bounce',
  skWanderingCubes: 'sk-wandering-cubes',
  skWave: 'sk-wave',
  skLine: 'sk-line-material'
};

